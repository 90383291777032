import React, { useState } from "react";

export const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const cards = [
    { id: 1, image: "images/fitness-routine.jpg", title: "Card 1" },
    { id: 2, image: "images/personal-finance.jpg", title: "Card 2"},
    { id: 3, image: "images/health-wellness.jpg", title: "Card 3"},
    { id: 4, image: "images/work-productivity.jpg", title: "Card 4" },
    { id: 5, image: "images/travel.jpg", title: "Card 5"},
    { id: 6, image: "images/fitness-routine.jpg", title: "Card 6" },
    { id: 7, image: "images/personal-finance.jpg", title: "Card 7"},
    { id: 8, image: "images/health-wellness.jpg", title: "Card 8"},
    { id: 9, image: "images/work-productivity.jpg", title: "Card 9"},
    { id: 10, image: "images/travel.jpg", title: "Card 10" }
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? Math.max(0, cards.length - 5) : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === cards.length - 5 ? 0 : prevIndex + 1));
  };

  return (
    <div className="custom-carousel-container">
      <div className="custom-carousel-inner" style={{ transform: `translateX(-${currentIndex * 20}%)` }}>
        {cards.map((card) => (
          <div key={card.id} className="custom-carousel-item">
            <div className="custom-carousel-card">
              <img src={card.image} alt={card.title} className="custom-carousel-card-img" />
              <div className="custom-carousel-card-body">
                <h5 className="custom-carousel-card-title">{card.title}</h5>
                <p className="custom-carousel-card-text">{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button className="custom-carousel-control custom-carousel-control-prev" onClick={handlePrev}>
        &#10094;
      </button>
      <button className="custom-carousel-control custom-carousel-control-next" onClick={handleNext}>
        &#10095;
      </button>
    </div>
  );
};
