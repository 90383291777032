import React from 'react';
import Checklist from './Checklist'; // Import the Checklist component
import './App.css'; // Import any additional styles if needed

function App() {
  return (
    <div className="App">
      <Checklist /> {/* Render the Checklist component */}
    </div>
  );
}

export default App;
