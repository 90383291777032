import React from 'react';
import { Navigation } from './components/navigation'; // Adjust the path if necessary
import { Header } from './components/header'; // Adjust the path if necessary
import './new.css';
import { Carousel } from "./components/carousel";
import './overlay.css';
// src/Checklist.js
import FadeInOnScroll from './components/FadeInOnScroll';


// Card Component
function Card({ title, coverImage, characterImage, onClick }) {
  return (
    <div className="card" onClick={onClick}>
      <div className="wrapper">
        <img src={coverImage} alt={`${title} Cover`} className="cover-image" />
      </div>
      <h3 className="title">{title}</h3>
      <img src={characterImage} alt={`${title} Character`} className="character" />
    </div>
  );
}



// FeatureItem Component
function FeatureItem({ id, icon, label, description }) {
  return (
    <div className="feature-item">
      <label htmlFor={id} className="feature-label">
        <img src={icon} alt={`${label} Icon`} className="feature-icon" />
        <div className="separator-vertical"></div>
        <div className="label-container">
          <h2 className="label">{label}</h2>
          <p className="feature-description">{description}</p>
        </div>
      </label>
    </div>
  );
}


// FeatureList Component
function FeatureList({ items }) {
  return (
    <div className="feature-list-container">
      <div className="feature-heading">
        <h1 className='bold-heading'>Select what you'd like to focus on Fitness first</h1>
      </div>
      <div className="feature-columns">
        {items.map((item, index) => (
          <FeatureItem key={index} {...item} />
        ))}
      </div>
    { /* <button className="download-pdf" id="download-pdf">Download PDF</button> */}
    </div>
  );
}


// Checklist Component
function Checklist() {
  const cards = [
    { title: 'Fitness Routine', coverImage: 'images/fitness-routine.jpg', characterImage: 'images/fit.png' },
    { title: 'Personal Finance', coverImage: 'images/personal-finance.jpg', characterImage: 'images/finance.png' },
    { title: 'Health & Wellness', coverImage: 'images/health-wellness.jpg', characterImage: 'images/health.png' },
    { title: 'Work Productivity', coverImage: 'images/work-productivity.jpg', characterImage: 'images/work.png' },
    { title: 'Travel', coverImage: 'images/travel.jpg', characterImage: 'images/travell.png' },
    { title: 'Food & Nutrition', coverImage: 'images/food-nutrition.jpg', characterImage: 'images/food.png' },
    { title: 'Gardening', coverImage: 'images/gardening.jpg', characterImage: 'images/garden.png' },
    { title: 'Home Management', coverImage: 'images/home-management.jpg', characterImage: 'images/home.png' },
    { title: 'Time Management', coverImage: 'images/time-management.jpg', characterImage: 'images/time.png' },
    { title: 'Personal Growth', coverImage: 'images/personal-growth.jpg', characterImage: 'images/growth.png' },
    { title: 'Music Practice', coverImage: 'images/music-practice.jpg', characterImage: 'images/music-practice-character.png' },
    { title: 'Self Care', coverImage: 'images/self-care.jpg', characterImage: 'images/self-care-character.png' },
    { title: 'Goal Setting', coverImage: 'images/goal-setting.jpg', characterImage: 'images/goal-setting-character.png' },
    { title: 'Stress Management', coverImage: 'images/stress-management.jpg', characterImage: 'images/stress-management-character.png' },
    { title: 'Cooking & Baking', coverImage: 'images/cooking-baking.jpg', characterImage: 'images/cooking-baking-character.png' },
  ];

  const features = [
    { id: 'feature1', icon: 'images/overlay/cardio.gif', label: 'Cardio Workouts', description: 'Boost Your Endurance' },
    { id: 'feature2', icon: 'images/overlay/weightlifting.gif', label: 'Strength Training', description: 'Build Muscle Mass' },
    { id: 'feature3', icon: 'images/overlay/yoga.gif', label: 'Recovery', description: 'Enhance Flexibility' },
    { id: 'feature4', icon: 'images/overlay/schedule.gif', label: 'Workout Schedule', description: 'Plan Your Routine' },
    { id: 'feature5', icon: 'images/overlay/goal.gif', label: 'Fitness Goals', description: 'Track Your Progress' },
    { id: 'feature6', icon: 'images/overlay/personal-trainer.gif', label: 'Personal Training', description: 'Get Expert Guidance' },
    { id: 'feature7', icon: 'images/overlay/bodycombat.gif', label: 'Group Classes', description: 'Join Group Sessions' },
    { id: 'feature8', icon: 'images/overlay/pull-up.gif', label: 'Home Workouts', description: 'Exercise At Home' },
    { id: 'feature9', icon: 'images/overlay/gym.gif', label: 'Gym Workouts', description: 'Utilize Gym Facilities' },
    { id: 'feature10', icon: 'images/overlay/cycling.gif', label: 'Outdoor Activities', description: 'Enjoy Fresh Air' },
    { id: 'feature11', icon: 'images/overlay/sleep.gif', label: 'Recovery Techniques', description: 'Improve Rest Quality' },
    { id: 'feature12', icon: 'images/overlay/goal (1).gif', label: 'Fitness Challenges', description: 'Push Your Limits' },
  ];

  
  return (
    <div>
      <Navigation />
      <Header data={{ /* Add any data needed for the Header component */ }} />
      <div className="two">
        <h1>
          Top Trending Checklists
          <span>Click to Download</span>
        </h1>
     
      </div>
      <div>
      <Carousel />
      </div>
           <div className="two">
        <h1>
          Kickstart your day by choosing your checklist
          <span>You can always add more in the future</span>
        </h1>
      </div>
      <FadeInOnScroll>
      <div className="card-container">
        {cards.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </FadeInOnScroll>
    <FadeInOnScroll>

      <div className="two">
        <h1>
          Checklist categories
          <span>Help us tailor the best experience for you</span>
        </h1>
      </div>
      </FadeInOnScroll>
      <br />
      <FadeInOnScroll>
      <div className="feature-lists-container">
        <FeatureList items={features} />
        <FeatureList items={features} />
        <FeatureList items={features} />
        
      </div>
      </FadeInOnScroll>
    </div>      
    

  );
}

export default Checklist;
